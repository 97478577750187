import { useCallback, useContext } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useToast } from '~/hooks'
import { GTM, GTMEvent } from '~/lib'
import { CustomerListsProviderContext } from '~/providers/CustomerListsProvider'
import { Client } from '../../customClients/client'
import { useSession } from '../useSession'

export const useCustomerListsDrawer = () => {
  return useContext(CustomerListsProviderContext)
}

export function useWishlist(
  selectedProducts: {
    sku: string
    quantity: number
  }[] = [],
  isFetch = false,
  isSaveCart = false
) {
  const { session } = useSession()
  const { openDrawer, setCustomerLists } = useCustomerListsDrawer() || {}
  const { showNotification, closeNotification } = useToast()
  const selectedProductIds = selectedProducts.map((product) => {
    return product.sku
  })

  const { data: lists, refetch: getLists } = useQuery(
    ['lists', session, selectedProductIds],
    async () => {
      const response = await Client.lists.getLists(selectedProductIds, session)
      return response.getValue()
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: isFetch && !!session,
    }
  )

  const renderNotification = useCallback(
    (id, type, content) => {
      showNotification({
        id,
        children: (
          <p className='flex justify-between'>
            <span>{content}</span>
            <button
              type='button'
              onClick={() => {
                closeNotification(type)
                openDrawer(id)
              }}>
              Show
            </button>
          </p>
        ),
        type: 'default',
        onClose: closeNotification,
      })
    },
    [openDrawer, closeNotification, showNotification]
  )

  const handleCustomerListsSuccess = useCallback(
    (response, type, requestId, productCount = 0) => {
      if (response.getErrorSafe()) {
        return false
      }
      const lists = response.getValueSafe()
      setCustomerLists(response.getValueSafe())
      let content = null
      const targetList = lists.find(({ id, name }) => {
        return requestId === id || requestId === name
      })
      if (type === 'addToList') {
        content = (
          <>
            {productCount} items were added to <b>{targetList?.name}</b> list
          </>
        )
      } else if (type === 'removeToList') {
        content = (
          <>
            The product removed from <b>{targetList?.name}</b> list
          </>
        )
      } else if (type === 'updateList') {
        content = (
          <>
            The product quantity has been updated in <b>{targetList?.name}</b>{' '}
            list
          </>
        )
      } else if (isSaveCart) {
        content = <>Your cart has been saved</>
      } else {
        content = <>{requestId} created</>
      }
      return renderNotification(targetList?.id, type, content)
    },
    [setCustomerLists, isSaveCart, renderNotification]
  )

  const addToList = useCallback(
    async (listId: string) => {
      const response = await Client.lists.addToList(
        listId,
        selectedProductIds,
        session
      )

      handleCustomerListsSuccess(
        response,
        'addToList',
        listId,
        selectedProductIds?.length
      )
      await getLists()
    },
    [getLists, selectedProductIds, session, handleCustomerListsSuccess]
  )

  const removeToList = useCallback(
    async (listId: string) => {
      const response = await Client.lists.removeToList(
        listId,
        selectedProductIds,
        session
      )
      handleCustomerListsSuccess(response, 'removeToList', listId)
      await getLists()
    },
    [getLists, selectedProductIds, session, handleCustomerListsSuccess]
  )

  const createList = useCallback(
    async (name: string) => {
      const response = await Client.lists.createList(
        name,
        selectedProducts,
        session
      )
      if (!response.getErrorSafe()) {
        GTM.dispatch(GTMEvent.CREATE_LIST)
      }
      handleCustomerListsSuccess(response, 'createList', name)
      await getLists()
    },
    [getLists, selectedProducts, session, handleCustomerListsSuccess]
  )

  const updateList = useCallback(
    async (listId: string, productId: string, quantity: number) => {
      const response = await Client.lists.updateList(
        listId,
        session,
        productId,
        quantity
      )
      handleCustomerListsSuccess(response, 'updateList', listId)
      await getLists()
    },
    [getLists, handleCustomerListsSuccess, session]
  )

  return {
    lists,
    getLists,
    addToList,
    createList,
    removeToList,
    updateList,
  }
}
